//14 client slider


window.isPaused = false;
window.onTimerChange = function onTimerChange() {


    var isPaused = false;

    if (!window.isPaused) {



        var firstLI = document.getElementById("product_listclients").firstElementChild;
        var lastLI = document.getElementById("product_listclients").lastElementChild;
        var lightgreen = false;
        var red = false;
        var purple = false;
        var blue = false;
        var green = false;

        var list = document.getElementById("product_listclients"); // Get the <ul> element to insert a new node

        if (list.classList.contains('LIGHTGREEN')) {
            lightgreen = true;
        }
        if (lightgreen) {
            list.children[3].classList.contains('client--intro').add('boxshadowlightgreen');
        }

        lastLI.insertAdjacentElement("afterend", firstLI);
        // var currentId = list.children[2].id;
        // currentId.classList.remove("");

        currentId = list.children[3].id;
        if (currentId === 'client1') {
            document.getElementById("clientname1").style.display = "block";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';


            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.add('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');


        } else if (currentId === 'client2') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'block';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.add('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client3') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'block';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.add('dotactive');

        } else if (currentId === 'client4') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'block';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.add('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client5') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'block';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.add('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client6') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'block';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.add('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client7') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'block';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.add('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client8') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'block';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.add('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client9') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'block';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.add('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client10') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'block';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.add('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client11') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'block';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.add('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client12') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'block';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.add('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client13') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'block';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.add('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client14') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'block';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.add('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client15') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'block';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.add('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client16') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'block';
            document.getElementById("clientname17").style.display = 'none';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.add('dotactive');
            document.getElementById("142").classList.remove('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        } else if (currentId === 'client17') {

            document.getElementById("clientname1").style.display = "none";
            document.getElementById("clientname2").style.display = 'none';
            document.getElementById("clientname3").style.display = 'none';
            document.getElementById("clientname4").style.display = 'none';
            document.getElementById("clientname5").style.display = 'none';
            document.getElementById("clientname6").style.display = 'none';
            document.getElementById("clientname7").style.display = 'none';
            document.getElementById("clientname8").style.display = 'none';
            document.getElementById("clientname9").style.display = 'none';
            document.getElementById("clientname10").style.display = 'none';
            document.getElementById("clientname11").style.display = 'none';
            document.getElementById("clientname12").style.display = 'none';
            document.getElementById("clientname13").style.display = 'none';
            document.getElementById("clientname14").style.display = 'none';
            document.getElementById("clientname15").style.display = 'none';
            document.getElementById("clientname16").style.display = 'none';
            document.getElementById("clientname17").style.display = 'block';

            document.getElementById("12").classList.remove('dotactive');
            document.getElementById("22").classList.remove('dotactive');
            document.getElementById("32").classList.remove('dotactive');
            document.getElementById("42").classList.remove('dotactive');
            document.getElementById("52").classList.remove('dotactive');
            document.getElementById("62").classList.remove('dotactive');
            document.getElementById("72").classList.remove('dotactive');
            document.getElementById("82").classList.remove('dotactive');
            document.getElementById("92").classList.remove('dotactive');
            document.getElementById("102").classList.remove('dotactive');
            document.getElementById("112").classList.remove('dotactive');
            document.getElementById("122").classList.remove('dotactive');
            document.getElementById("132").classList.remove('dotactive');
            document.getElementById("142").classList.add('dotactive');
            document.getElementById("152").classList.remove('dotactive');
            document.getElementById("162").classList.remove('dotactive');
            document.getElementById("172").classList.remove('dotactive');

        }
    }

}

window.changeContent14 = function changeContent14(currentId) {

    var Clientname1 = document.getElementById("clientname1");
    var Clientname2 = document.getElementById("clientname2");
    var Clientname3 = document.getElementById("clientname3");
    var Clientname4 = document.getElementById("clientname4");
    var Clientname5 = document.getElementById("clientname5");
    var Clientname6 = document.getElementById("clientname6");
    var Clientname7 = document.getElementById("clientname7");
    var Clientname8 = document.getElementById("clientname8");
    var Clientname9 = document.getElementById("clientname9");
    var Clientname10 = document.getElementById("clientname10");
    var Clientname11 = document.getElementById("clientname11");
    var Clientname12 = document.getElementById("clientname12");
    var Clientname13 = document.getElementById("clientname13");
    var Clientname14 = document.getElementById("clientname14");
    var Clientname15 = document.getElementById("clientname15");
    var Clientname16 = document.getElementById("clientname16");
    var Clientname17 = document.getElementById("clientname17");


    var dot4 = document.getElementById("12");
    var dot5 = document.getElementById("22");
    var dot6 = document.getElementById("32");
    var dot7 = document.getElementById("42");
    var dot8 = document.getElementById("52");
    var dot9 = document.getElementById("62");
    var dot10 = document.getElementById("72");
    var dot11 = document.getElementById("82");
    var dot12 = document.getElementById("92");
    var dot13 = document.getElementById("102");
    var dot14 = document.getElementById("112");
    var dot15 = document.getElementById("122");
    var dot16 = document.getElementById("132");
    var dot17 = document.getElementById("142");
    var dot1 = document.getElementById("152");
    var dot2 = document.getElementById("162");
    var dot3 = document.getElementById("172");

    var isPaused = false;

    var list = document.getElementById("product_listclients");
    list.addEventListener("click", function (e) {
        if (e.target && e.target.matches("li.item")) {
            e.target.className = "foo";

            alert("clicked " + e.target.innerText);
        }
    });

    window.isPaused = true;
    var firstLI = document.getElementById("product_listclients").firstElementChild;
    var Client1Li = document.getElementById("client1");
    var Client2Li = document.getElementById("client2");
    var Client3Li = document.getElementById("client3");
    var Client4Li = document.getElementById("client4");
    var Client5Li = document.getElementById("client5");
    var Client6Li = document.getElementById("client6");
    var Client7Li = document.getElementById("client7");
    var Client8Li = document.getElementById("client8");
    var Client9Li = document.getElementById("client9");
    var Client10Li = document.getElementById("client10");
    var Client11Li = document.getElementById("client11");
    var Client12Li = document.getElementById("client12");
    var Client13Li = document.getElementById("client13");
    var Client14Li = document.getElementById("client14");
    var Client15Li = document.getElementById("client15");
    var Client16Li = document.getElementById("client16");
    var Client17Li = document.getElementById("client17");


    setTimeout(() => {
        window.isPaused = false;

    }, 5000);

    if (currentId === 'client1' || currentId === "152") {


        firstLI.insertAdjacentElement("afterend", Client11Li);



        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);



        document.getElementById("clientname1").style.display = 'block';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.add('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');


    } else if (currentId === 'client2' || currentId === "162") {

        firstLI.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'block';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.add('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client3' || currentId === "172") {

        firstLI.insertAdjacentElement("afterend", Client13Li);

        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'block';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.add('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client4' || currentId === "12") {

        firstLI.insertAdjacentElement("afterend", Client1Li);

        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'block';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.add('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client5' || currentId === "22") {
        firstLI.insertAdjacentElement("afterend", Client2Li);


        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'block';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.add('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client6' || currentId === "32") {

        firstLI.insertAdjacentElement("afterend", Client3Li);

        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'block';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.add('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client7' || currentId === "42") {

        firstLI.insertAdjacentElement("afterend", Client4Li);


        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'block';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.add('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client8' || currentId === "52") {

        firstLI.insertAdjacentElement("afterend", Client5Li);


        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'block';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.add('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client9' || currentId === "62") {

        firstLI.insertAdjacentElement("afterend", Client6Li);


        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'block';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.add('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client10' || currentId === "72") {

        firstLI.insertAdjacentElement("afterend", Client7Li);

        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'block';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.add('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client11' || currentId === "82") {

        firstLI.insertAdjacentElement("afterend", Client8Li);


        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'block';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.add('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client12' || currentId === "92") {


        firstLI.insertAdjacentElement("afterend", Client9Li);

        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'block';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.add('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client13' || currentId === "102") {


        firstLI.insertAdjacentElement("afterend", Client10Li);


        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'block';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.add('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client14' || currentId === "112") {


        firstLI.insertAdjacentElement("afterend", Client10Li);


        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Client17Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client13Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'block';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.add('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client15' || currentId === "122") {


        firstLI.insertAdjacentElement("afterend", Client12Li);


        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Client17Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'block';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.add('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client16' || currentId === "132") {


        firstLI.insertAdjacentElement("afterend", Client13Li);



        Client13Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Client17Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'block';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.add('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client17' || currentId === "142") {


        firstLI.insertAdjacentElement("afterend", Client14Li);



        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Client17Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'block';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.add('dotactive');
    }

}

window.clientTimer2 = function clientTimer2() {
    setInterval(onTimerChange, 5000);
}

window.addEventListener('load', () => {
    clientTimer2();

});